<template>
  <NuxtLayout>
    <div class="mx-auto mt-auto max-w-4xl text-center px-4 py-base-2">
      <div class="fk-prose mb-4">
        <h1>{{ $t("general.error.heading") }}</h1>
      </div>
      <NuxtLink :to="localePath('/')" class="btn">
        {{ $t("general.error.toHome") }}
      </NuxtLink>
    </div>
    <!-- {{ error }} -->
  </NuxtLayout>
</template>

<script setup lang="ts">
const localePath = useLocalePath();

defineProps<{
  error: {
    type: object;
    required: true;
  };
}>();
</script>

export default defineNuxtPlugin(async () => {
  const { fetchData, regions } = useStaticData();
  await fetchData();

  const url = useRequestURL();
  let hostname = url.hostname;
  hostname = hostname.split(".")[0];

  useState("hostname", () => hostname);
  const regionId = useState("regionId", () => 0);
  const region = regions.value?.find((r) =>
    r.attributes.url.includes(hostname),
  );
  regionId.value = region?.id ?? 0;
});

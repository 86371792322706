import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccountApplicationFilterBar, LazyAccountApplicationItem, LazyAccountCompanyItem, LazyAccountOrderItem, LazyAccountVacancyFilterBar, LazyAccountVacancyItem, LazyBlockButtons, LazyBlockCallToAction, LazyBlockCollectionList, LazyBlockFaq, LazyBlockFormContainer, LazyBlockFormDefault, LazyBlockFormJobAlert, LazyBlockFormJobAlertUnsubscribe, LazyBlockForm, LazyBlockHowItWorks, LazyBlockIubenda, LazyBlockPlans, LazyBlockSearchVacancies, LazyBlockSearchVacanciesLinks, LazyBlockSitemap, LazyBlockStatistics, LazyBlockText, LazyBlockTextMedia, LazyBlockUSPs, LazyBlockWorkAtSlider, LazyBlogIntro, LazyBlogItem, LazyCompanyFilterBar, LazyCompanyIntro, LazyCompanyItem, LazyFilterBar, LazyIntro, LazyItem, LazyPagination, LazyVacancyFilterBar, LazyVacancyIntro, LazyVacancyItem, LazyProseA, LazyProseBlockquote, LazyProseCode, LazyProseEm, LazyProseH1, LazyProseH2, LazyProseH3, LazyProseH4, LazyProseH5, LazyProseH6, LazyProseHr, LazyProseImg, LazyProseLi, LazyProseOl, LazyProseP, LazyProsePre, LazyProseScript, LazyProseStrong, LazyProseTable, LazyProseTbody, LazyProseTd, LazyProseTh, LazyProseThead, LazyProseTr, LazyProseUl } from '#components'
const lazyGlobalComponents = [
  ["AccountApplicationFilterBar", LazyAccountApplicationFilterBar],
["AccountApplicationItem", LazyAccountApplicationItem],
["AccountCompanyItem", LazyAccountCompanyItem],
["AccountOrderItem", LazyAccountOrderItem],
["AccountVacancyFilterBar", LazyAccountVacancyFilterBar],
["AccountVacancyItem", LazyAccountVacancyItem],
["BlockButtons", LazyBlockButtons],
["BlockCallToAction", LazyBlockCallToAction],
["BlockCollectionList", LazyBlockCollectionList],
["BlockFaq", LazyBlockFaq],
["BlockFormContainer", LazyBlockFormContainer],
["BlockFormDefault", LazyBlockFormDefault],
["BlockFormJobAlert", LazyBlockFormJobAlert],
["BlockFormJobAlertUnsubscribe", LazyBlockFormJobAlertUnsubscribe],
["BlockForm", LazyBlockForm],
["BlockHowItWorks", LazyBlockHowItWorks],
["BlockIubenda", LazyBlockIubenda],
["BlockPlans", LazyBlockPlans],
["BlockSearchVacancies", LazyBlockSearchVacancies],
["BlockSearchVacanciesLinks", LazyBlockSearchVacanciesLinks],
["BlockSitemap", LazyBlockSitemap],
["BlockStatistics", LazyBlockStatistics],
["BlockText", LazyBlockText],
["BlockTextMedia", LazyBlockTextMedia],
["BlockUSPs", LazyBlockUSPs],
["BlockWorkAtSlider", LazyBlockWorkAtSlider],
["BlogIntro", LazyBlogIntro],
["BlogItem", LazyBlogItem],
["CompanyFilterBar", LazyCompanyFilterBar],
["CompanyIntro", LazyCompanyIntro],
["CompanyItem", LazyCompanyItem],
["FilterBar", LazyFilterBar],
["Intro", LazyIntro],
["Item", LazyItem],
["Pagination", LazyPagination],
["VacancyFilterBar", LazyVacancyFilterBar],
["VacancyIntro", LazyVacancyIntro],
["VacancyItem", LazyVacancyItem],
["ProseA", LazyProseA],
["ProseBlockquote", LazyProseBlockquote],
["ProseCode", LazyProseCode],
["ProseEm", LazyProseEm],
["ProseH1", LazyProseH1],
["ProseH2", LazyProseH2],
["ProseH3", LazyProseH3],
["ProseH4", LazyProseH4],
["ProseH5", LazyProseH5],
["ProseH6", LazyProseH6],
["ProseHr", LazyProseHr],
["ProseImg", LazyProseImg],
["ProseLi", LazyProseLi],
["ProseOl", LazyProseOl],
["ProseP", LazyProseP],
["ProsePre", LazyProsePre],
["ProseScript", LazyProseScript],
["ProseStrong", LazyProseStrong],
["ProseTable", LazyProseTable],
["ProseTbody", LazyProseTbody],
["ProseTd", LazyProseTd],
["ProseTh", LazyProseTh],
["ProseThead", LazyProseThead],
["ProseTr", LazyProseTr],
["ProseUl", LazyProseUl],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

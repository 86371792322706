// formkit.config.ts
import type { DefaultConfigOptions } from "@formkit/vue";
import { generateClasses } from "@formkit/themes";
import { nl, en } from "@formkit/i18n";
import fktheme from "./fk-theme";

import { createMultiStepPlugin } from "@formkit/addons";
import "@formkit/addons/css/multistep";

const config: DefaultConfigOptions = {
  plugins: [createMultiStepPlugin()],
  locale: "nl",
  locales: { nl, en },
  config: {
    classes: generateClasses(fktheme),
  },
};
export default config;

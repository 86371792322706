import { default as _91_46_46_46slug_93k03mYP6sNTMeta } from "/opt/render/project/src/frontend/pages/[...slug].vue?macro=true";
import { default as _91slug_932ZhRCXH2urMeta } from "/opt/render/project/src/frontend/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93695wQYNH4tMeta } from "/opt/render/project/src/frontend/pages/company/[slug].vue?macro=true";
import { default as loginVA3SfvqToPMeta } from "/opt/render/project/src/frontend/pages/login.vue?macro=true";
import { default as _91_91id_93_938bo1pTYSvCMeta } from "/opt/render/project/src/frontend/pages/my-account/application/[[id]].vue?macro=true";
import { default as applications7QZsnevgkWMeta } from "/opt/render/project/src/frontend/pages/my-account/applications.vue?macro=true";
import { default as companiesOsFkeQ7hxFMeta } from "/opt/render/project/src/frontend/pages/my-account/companies.vue?macro=true";
import { default as _91_91id_93_93xhDwmOnjkhMeta } from "/opt/render/project/src/frontend/pages/my-account/company/[[id]].vue?macro=true";
import { default as historyVoa6zolVdXMeta } from "/opt/render/project/src/frontend/pages/my-account/credits/history.vue?macro=true";
import { default as indexCdFXr1ud1QMeta } from "/opt/render/project/src/frontend/pages/my-account/credits/index.vue?macro=true";
import { default as indexXVrKaHRrnxMeta } from "/opt/render/project/src/frontend/pages/my-account/index.vue?macro=true";
import { default as order_45paymentgGRPzmXcZtMeta } from "/opt/render/project/src/frontend/pages/my-account/order-payment.vue?macro=true";
import { default as _91_91id_93_93Aa4vVShgKzMeta } from "/opt/render/project/src/frontend/pages/my-account/order/[[id]].vue?macro=true";
import { default as ordershyqbjhhwwjMeta } from "/opt/render/project/src/frontend/pages/my-account/orders.vue?macro=true";
import { default as profileubAtl2ccZQMeta } from "/opt/render/project/src/frontend/pages/my-account/profile.vue?macro=true";
import { default as vacanciesAg3uXGt1wMMeta } from "/opt/render/project/src/frontend/pages/my-account/vacancies.vue?macro=true";
import { default as _91_91id_93_93F5YQH1UOfBMeta } from "/opt/render/project/src/frontend/pages/my-account/vacancy/[[id]].vue?macro=true";
import { default as my_45accountipGuNmGAATMeta } from "/opt/render/project/src/frontend/pages/my-account.vue?macro=true";
import { default as _91slug_93GMVej4WANLMeta } from "/opt/render/project/src/frontend/pages/place/[slug].vue?macro=true";
import { default as _91slug_93OkR6K0Xf9OMeta } from "/opt/render/project/src/frontend/pages/profession/[slug].vue?macro=true";
import { default as _91date_93gogSsQr0jiMeta } from "/opt/render/project/src/frontend/pages/vacancy/[slug]/[date].vue?macro=true";
import { default as _91slug_93hkykhUPYFnMeta } from "/opt/render/project/src/frontend/pages/vacancy/[slug].vue?macro=true";
import { default as component_45stubPbdwS6RVToMeta } from "/opt/render/project/src/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubPbdwS6RVTo } from "/opt/render/project/src/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___nl",
    path: "/:slug(.*)*",
    component: () => import("/opt/render/project/src/frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___nl",
    path: "/blog/:slug()",
    component: () => import("/opt/render/project/src/frontend/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "company-slug___nl",
    path: "/bedrijven/:slug()",
    component: () => import("/opt/render/project/src/frontend/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: "login___nl",
    path: "/inloggen",
    component: () => import("/opt/render/project/src/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45accountipGuNmGAATMeta?.name,
    path: "/mijn-account",
    meta: my_45accountipGuNmGAATMeta || {},
    component: () => import("/opt/render/project/src/frontend/pages/my-account.vue").then(m => m.default || m),
    children: [
  {
    name: "my-account-application-id___nl",
    path: "sollicitaties/:id?",
    meta: _91_91id_93_938bo1pTYSvCMeta || {},
    component: () => import("/opt/render/project/src/frontend/pages/my-account/application/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "my-account-applications___nl",
    path: "sollicitaties-overzicht",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/applications.vue").then(m => m.default || m)
  },
  {
    name: "my-account-companies___nl",
    path: "bedrijfsgegevens-overzicht",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/companies.vue").then(m => m.default || m)
  },
  {
    name: "my-account-company-id___nl",
    path: "bedrijfsgegevens/:id?",
    meta: _91_91id_93_93xhDwmOnjkhMeta || {},
    component: () => import("/opt/render/project/src/frontend/pages/my-account/company/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "my-account-credits-history___nl",
    path: "credits/history",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/credits/history.vue").then(m => m.default || m)
  },
  {
    name: "my-account-credits___nl",
    path: "credits",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/credits/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account___nl",
    path: "",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-order-payment___nl",
    path: "order-betaling",
    meta: order_45paymentgGRPzmXcZtMeta || {},
    component: () => import("/opt/render/project/src/frontend/pages/my-account/order-payment.vue").then(m => m.default || m)
  },
  {
    name: "my-account-order-id___nl",
    path: "order/:id()",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/order/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "my-account-orders___nl",
    path: "orders",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/orders.vue").then(m => m.default || m)
  },
  {
    name: "my-account-profile___nl",
    path: "mijn-gegevens",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/profile.vue").then(m => m.default || m)
  },
  {
    name: "my-account-vacancies___nl",
    path: "vacatures-overzicht",
    component: () => import("/opt/render/project/src/frontend/pages/my-account/vacancies.vue").then(m => m.default || m)
  },
  {
    name: "my-account-vacancy-id___nl",
    path: "vacatures/:id?",
    meta: _91_91id_93_93F5YQH1UOfBMeta || {},
    component: () => import("/opt/render/project/src/frontend/pages/my-account/vacancy/[[id]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "place-slug___nl",
    path: "/vacatures-in/:slug()",
    component: () => import("/opt/render/project/src/frontend/pages/place/[slug].vue").then(m => m.default || m)
  },
  {
    name: "profession-slug___nl",
    path: "/vacatures-in-beroepsgroep/:slug()",
    component: () => import("/opt/render/project/src/frontend/pages/profession/[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacancy-slug___nl",
    path: "/vacatures/:slug()",
    component: () => import("/opt/render/project/src/frontend/pages/vacancy/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "vacancy-slug-date___nl",
    path: ":date()",
    meta: _91date_93gogSsQr0jiMeta || {},
    component: () => import("/opt/render/project/src/frontend/pages/vacancy/[slug]/[date].vue").then(m => m.default || m)
  }
]
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/zoeken",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacature/:pathMatch(.*)",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/administratief-financieel",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/advies-consultancy",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/agrarisch-milieu",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/bouw",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/handel-detailhandel",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/horeca-recreatie",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/hr-recruitment",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/ict",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/industrie-productie",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/juridisch",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/klantenservice",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/kunst-cultuur-entertainment",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/management-beleid",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/marketing-communicatie",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/marketing-sales",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/milieu-natuur",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/onderwijs-kinderopvang",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/overheid",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/overige",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/schoonmaak-facilitair",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/sport-recreatie-toerisme",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/techniek",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/transport-logistiek",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacatures-in/zorg-gezondheid",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/bedrijvengids",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/werkgever",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/werkgever/:pathMatch(.*)",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/login",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/privacyverklaring",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/professions-sitemap.xml",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/places-sitemap.xml",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/blog-sitemap.xml",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/vacancies-sitemap.xml",
    component: component_45stubPbdwS6RVTo
  },
  {
    name: component_45stubPbdwS6RVToMeta?.name,
    path: "/companies-sitemap.xml",
    component: component_45stubPbdwS6RVTo
  }
]
import { createGtm } from "@gtm-support/vue-gtm";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  if (config) {
    if (config.public) {
      if (config.public.googleTagManagerID) {
        nuxtApp.vueApp.use(
          createGtm({
            id: config.public.googleTagManagerID, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
            defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results. Defaults to false, so the script is loaded `async` by default
            compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
            enabled: true, // Defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
            debug: false, // Whether or not display console logs debugs (optional)
            loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
            vueRouter: useRouter(), // Pass the router instance to automatically sync with router (optional)
            trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
          }),
        );
      } else {
        throw createError({
          statusMessage:
            "googleTagManagerID is empty or not found in runtimeConfig public in nuxt.config.ts.",
        });
      }
    } else {
      throw createError({ statusMessage: "public not found in runtimeConfig" });
    }
  } else {
    throw createError({
      statusMessage: "runtimeConfig not found in nuxt.config.ts",
    });
  }
});

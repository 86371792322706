export default {
  // Global styles apply to _all_ inputs with matching section keys
  global: {
    fieldset: "",
    help: "text-sm opacity-50",
    inner:
      "formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none transition-all formkit-disabled:text-secondary ring-0",
    input:
      "appearance-none bg-transparent focus:outline-none focus:ring-0 focus:shadow-none !accent-dark",
    label: "block fk-label mb-1",
    legend: "",
    loaderIcon: "inline-flex items-center w-4 text-gray-600 animate-spin",
    message: "text-red-500 text-sm leading-tight mb-1 mt-2",
    messages: "list-none p-0 mt-1 mb-4",
    outer: "mb-4 formkit-disabled:opacity-50",
    prefixIcon:
      "pl-5 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto",
    suffixIcon:
      "pr-5 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto",
  },

  // Family styles apply to all inputs that share a common family
  "family:box": {
    decorator:
      "block relative h-5 w-5 mr-2 fk-input peer-checked:ring-dark peer-checked:bg-dark text-transparent peer-checked:text-light transition-all",
    decoratorIcon:
      "flex p-[3px] w-[18px] h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2",
    help: "mb-2 mt-1.5",
    input:
      "absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer",
    inner: "$remove:formkit-disabled:bg-gray-200",
    label: "$reset text-inherit select-none leading-tight pt-1",
    wrapper: "flex items-start",
    legend: "block mb-2 fk-label",
    options: "grid grid-columsn-1 gap-y-1 gap-x-4",
  },
  "family:button": {
    input:
      "$reset btn formkit-loading:!bg-dark formkit-loading:!text-light formkit-loading:cursor-default formkit-loading:before:w-4 formkit-loading:before:h-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-full formkit-loading:before:border-white formkit-loading:before:animate-spin",
    wrapper: "flex justify-end",
    prefixIcon: "$reset block w-4 -ml-2 mr-2 stretch",
    suffixIcon: "$reset block w-4 ml-2 stretch",
  },
  "family:dropdown": {
    dropdownWrapper:
      "my-2 w-full shadow-lg rounded [&::-webkit-scrollbar]:hidden",
    emptyMessageInner:
      "flex items-center justify-center text-sm p-2 text-center w-full text-gray-500 [&>span]:mr-3 [&>span]:ml-0",
    inner:
      "relative flex ring-1 ring-secondary focus-within:ring-dark focus-within:ring-2 rounded mb-1 formkit-disabled:focus-within:ring-secondary formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-blue-500",
    input: "w-full px-5 py-4",
    listbox: "bg-light text-dark shadow-lg rounded overflow-hidden",
    listboxButton: "flex w-12 self-stretch justify-center mx-auto",
    listitem:
      'pl-7 relative hover:text-primary data-[is-active="true"]:text-primary data-[is-active="true"]:aria-selected:bg-primary aria-selected:bg-primary aria-selected:!text-light',
    loaderIcon: "ml-auto",
    loadMoreInner:
      "flex items-center justify-center text-sm p-2 text-center w-full text-blue-500 formkit-loading:text-gray-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0",
    option: "py-2.5 px-4 bg-light text-dark",
    optionLoading: "text-[blue]",
    placeholder: "p-2.5 text-[gold]",
    selector: "flex w-full justify-between items-center [&u]",
    selectedIcon: "block absolute top-1/2 left-2 w-3 -translate-y-1/2",
    selectIcon:
      "flex box-content w-4 px-2 self-stretch grow-0 shrink-0 [&>svg]:w-[1em]",
  },
  "family:text": {
    inner:
      "flex items-center fk-input focus-within:ring-dark focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 mb-1",
    input:
      "w-full px-5 py-4 border-none text-base text-inherit placeholder-[var(--fk-placeholder-color)]",
  },
  "family:date": {
    inner:
      "flex items-center fk-input focus-within:ring-dark focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 mb-1",
    input: "w-full px-5 py-4 border-none text-inherit placeholder-[gold]",
  },

  // Specific styles apply only to a given input type
  color: {
    inner:
      "flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]",
    input:
      "$reset appearance-none w-full cursor-pointer border-none rounded p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none",
    suffixIcon: "min-w-[2.5em] pr-0 pl-0 m-auto",
  },
  file: {
    fileItem: "flex items-center text-inherit mb-1 last:mb-0",
    fileItemIcon: "w-4 mr-2 shrink-0",
    fileList:
      'shrink grow peer px-5 py-4 formkit-multiple:data-[has-multiple="true"]:mb-6',
    fileName: "break-all grow text-ellipsis",
    fileRemove:
      "relative z-[2] ml-auto text-[0px] hover:text-primary pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-blue-500 peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]",
    fileRemoveIcon: "block text-base w-3 relative z-[2]",
    inner: "relative cursor-pointer formkit-multiple:[&>button]:absolute",
    input:
      "cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]",
    noFiles:
      "flex w-full items-center px-5 py-4 text-[var(--fk-placeholder-color)]",
    noFilesIcon: "w-4 mr-2",
  },
  radio: {
    decorator: "!rounded-full",
    decoratorIcon: "w-5 p-[5px]",
  },
  range: {
    inner: "$reset flex items-center",
    input: "$reset w-full mb-1 h-2 p-0 rounded-full accent-primary",
    prefixIcon:
      "$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto",
    suffixIcon:
      "$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto",
  },
  select: {
    inner:
      "flex relative items-center fk-input mb-1 focus-within:ring-dark focus-within:ring-2 [&>span:first-child]:focus-within:text-[red]",
    input:
      'w-full pl-5 pr-8 py-4 border-none text-base text-inherit cursor-pointer placeholder-inherit formkit-multiple:p-0 data-[placeholder="true"]:text-[var(--fk-placeholder-color)] formkit-multiple:data-[placeholder="true"]:text-[var(--fk-placeholder-color)]',
    selectIcon:
      "flex p-[3px] shrink-0 w-5 mr-4 -ml-[1.5em] h-full pointer-events-none [&>svg]:w-[1em]",
    option: "formkit-multiple:p-3",
  },
  textarea: {
    inner:
      "flex rounded mb-1 fk-input focus-within:ring-dark [&>label:first-child]:focus-within:text-blue-500 focus-within:ring-2",
    input:
      "block w-full h-32 px-3 py-3 border-none text-base text-inherit placeholder-[var(--fk-placeholder-color)] focus:shadow-outline",
  },

  // PRO input styles
  autocomplete: {
    closeIcon: "block grow-0 shrink-0 w-3 mr-3.5",
    inner: "[&>div>[data-value]]:absolute [&>div>[data-value]]:mb-0",
    option: "grow text-ellipsis",
    selection:
      "static flex left-0 top-0 right-0 bottom-0 mt-0 mb-2 rounded bg-gray-100",
  },
  datepicker: {
    inner: "relative",
    panelWrapper:
      "absolute top-[calc(100%_+_0.5em)] shadow-[0_0_1.25em_rgba(0,0,0,.25)] rounded-md p-5 bg-light text-dark z-10",
    panelHeader:
      "grid grid-cols-[2.5em_1fr_2.5em] justify-center items-center border-b-2 mb-4 pb-4",
    input: "selection:bg-blue-400",
    monthsHeader: "flex items-center justify-center col-start-2 col-end-2",
    timeHeader: "flex items-center justify-center col-start-2 col-end-2",
    overlayPlaceholder: "text-gray-400",
    months: "flex flex-wrap",
    month: `
      flex items-center justify-center
      w-[calc(33%_-_1em)] m-2 p-2 rounded-md
      aria-selected:bg-primary aria-selected:text-white
      focus:outline focus:outline-2 focus:outline-primary focus:outline-offset-2 focus:text-black
      data-[is-extra=true]:opacity-25
      formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
    `,
    yearsHeader: "flex items-center justify-center col-start-2 col-end-2",
    years: "flex flex-wrap max-w-[35em]",
    year: `
      flex items-center justify-center
      w-[calc(20%_-_1em)] m-2 p-2 rounded-md
      aria-selected:bg-primary aria-selected:text-white
      focus:outline focus:outline-2 focus:outline-primary focus:outline-offset-2 focus:text-black
      data-[is-extra=true]:opacity-25
      formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
    `,
    weekDays: "flex",
    weekDay:
      "flex w-[2.25em] h-[1em] m-1 items-center justify-center rounded-md font-medium lowercase",
    week: "flex formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none",
    dayCell: `
      flex items-center justify-center
      w-[2.25em] h-[2.25em] m-1 p-2 rounded-md
      aria-selected:bg-primary aria-selected:text-white
      focus:outline focus:outline-2 focus:outline-primary focus:outline-offset-2 focus:text-black
      data-[is-extra=true]:opacity-25
      formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
    `,
    timeInput:
      "w-full border-2 border-gray-300 rounded-md p-2 my-[2em] focus-visible:outline-blue-500",
    daysHeader: "flex items-center justify-center",
    prev: "mr-auto px-3 py-1 hover:text-primary hover:rounded-lg col-start-1 col-end-1",
    prevLabel: "hidden",
    prevIcon: "flex w-3 select-none [&>svg]:w-full",
    dayButton:
      "appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-blue-500",
    monthButton:
      "appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-blue-500",
    yearButton:
      "appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-blue-500",
    next: "ml-auto px-3 py-1 hover:text-primary hover:rounded col-start-3 col-end-3",
    nextLabel: "hidden",
    nextIcon: "flex w-3 select-none [&>svg]:w-full",
    openButton: `
      appearance-none border-0 bg-transparent flex p-0 self-stretch cursor-pointer
      focus-visible:outline-none focus-visible:text-white focus-visible:bg-blue-500
    `,
    calendarIcon:
      "flex w-16 grow-0 shrink-0 self-stretch select-none [&>svg]:w-full [&>svg]:m-auto [&>svg]:max-h-[1em] [&>svg]:max-w-[1em]",
  },
  rating: {
    inner: "relative flex items-center w-[8em] formkit-disabled:bg-transparent",
    itemsWrapper: "w-full",
    onItems: "text-primary",
    onItemWrapper:
      "[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none",
    offItems: "text-secondary",
    offItemWrapper:
      "[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none",
  },
  repeater: {
    content: "grow p-3 flex flex-col align-center",
    controlLabel: "absolute opacity-0 pointer-events-none",
    controls: "flex flex-col items-center justify-center bg-gray-100 p-3",
    downControl:
      "hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25",
    fieldset: "py-4 px-5",
    help: "mb-2 mt-1.5",
    item: "flex w-full mb-1 rounded border border-gray-200",
    moveDownIcon: "block w-3 my-1",
    moveUpIcon: "block w-3 my-1",
    removeControl:
      "hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25",
    removeIcon: "block w-5 my-1",
    upControl:
      "hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25",
  },
  slider: {
    help: "mt-0 mb-1",
    sliderInner:
      'flex items-center py-1 [&>.formkit-max-value]:mb-0 [&>.formkit-max-value]:ml-8 [&>.formkit-max-value]:shrink [&>.formkit-max-value]:grow-0 [&>.formkit-icon]:bg-none [&>.formkit-icon]:border-none [&>.formkit-icon]:p-0 [&>.formkit-icon]:w-4 [&>.formkit-prefix-icon]:mr-2 [&>.formkit-suffix-icon]:ml-2 [&[data-has-mark-labels="true"]_.formkit-track]:mb-4',
    track: "grow relative z-[3] py-1",
    trackWrapper: "px-[2px] rounded-full bg-gray-200",
    trackInner: "h-[6px] mx-[2px] relative",
    fill: "h-full rounded-full absolute top-0 mx-[-4px] bg-blue-500",
    marks: "absolute pointer-events-none left-0 right-0 top-0 bottom-0",
    mark: 'absolute top-1/2 w-[3px] h-[3px] rounded-full -translate-x-1/2 -translate-y-1/2 bg-gray-400 data-[active="true"]:bg-white',
    markLabel:
      "absolute top-[calc(100%+0.5em)] left-1/2 text-gray-400 text-[0.66em] -translate-x-1/2",
    handles: "m-0 p-0 list-none",
    handle:
      'group w-4 h-4 rounded-full bg-white absolute top-1/2 left-0 z-[2] -translate-x-1/2 -translate-y-1/2 shadow-[inset_0_0_0_1px_rgba(0,0,0,0.1),0_1px_2px_0_rgba(0,0,0,0.8)] focus-visible:outline-0 focus-visible:ring-2 ring-blue-500 data-[is-target="true"]:z-[3]',
    tooltip:
      'absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-[4px] bg-blue-500 text-white py-1 px-2 text-xs leading-none whitespace-nowrap rounded-sm opacity-0 pointer-events-none transition-opacity after:content-[""] after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:-translate-y-[1px] after:border-4 after:border-transparent after:border-t-blue-500 group-hover:opacity-100 group-focus-visible:opacity-100 group-data-[show-tooltip="true"]:opacity-100',
    linkedValues: "flex items-start justify-between",
    minValue:
      'grow max-w-[45%] mb-0 relative [&_.formkit-inner::after]:content-[""] [&_.formkit-inner::after]:absolute [&_.formkit-inner::after]:left-[105%] [&_.formkit-inner::after]:-translate-y-1/2 [&_.formkit-inner::after]:w-[10%] [&_.formkit-inner::after]:h-[1px] [&_.formkit-inner::after]:bg-gray-500',
    maxValue: "grow max-w-[45%] mb-0 relative",
    chart:
      "relative z-[2] mb-2 flex justify-between items-center w-full aspect-[3/1]",
    chartBar:
      'absolute bottom-0 h-full bg-gray-400 opacity-[.66] data-[active="false"]:opacity-[.25]',
  },
  taglist: {
    input: "px-1 py-1 w-[0%] grow",
    removeSelection: "w-2.5 mx-1 self-center leading-none",
    tag: "flex items-center my-1 p-1 bg-primary text-xs rounded-full",
    tagWrapper:
      "mr-1 focus:outline-none focus:text-white [&>div]:focus:bg-blue-500 [&>div>button]:focus:text-white",
    tagLabel: "pl-2 pr-1",
    tags: "flex items-center flex-wrap w-full py-1.5 px-2",
  },
  toggle: {
    altLabel: "block w-full mb-1 font-bold text-sm",
    inner: "$reset inline-block ml-3",
    label: "$reset font-secondary leading-tight text-xl", // block mb-2 text-primary text-sm uppercase font-bold tracking-[.2em]",
    input: "peer absolute opacity-0 pointer-events-none",
    innerLabel:
      "text-[10px] font-bold absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1",
    thumb:
      "relative left-0 aspect-square rounded-full transition-all w-5 bg-gray-100",
    track:
      "p-0.5 min-w-[3em] relative rounded-full transition-all bg-transparent ring-1 ring-secondary peer-checked:ring-0 peer-checked:bg-primary peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0",
    valueLabel: "font-bold text-sm",
    wrapper:
      "flex flex-row-reverse justify-between items-center pt-5 border-t border-secondary",
  },
  transferlist: {
    outer: `
      [&_.dnd-placeholder]:bg-blue-500 [&_.dnd-placeholder]:text-white
      [&_.dnd-placeholder_svg]:text-white
      [&_.dnd-children-hidden]:w-full [&_.dnd-children-hidden]:p-0 [&_.dnd-children-hidden]:flex [&_.dnd-children-hidden]:flex-col [&_.dnd-children-hidden]:border-none
      [&_.dnd-children-hidden_span]:hidden
      [&_.dnd-children-hidden_.formkit-transferlist-option]:hidden
      [&_.dnd-multiple-selections_span]:inline-block
      [&_.dnd-multiple-selections_.formkit-transferlist-option]:inline-block
    `,
    fieldset: "$reset max-w-2xl",
    wrapper:
      "flex max-h-[350px] flex-col sm:flex-row justify-between w-full max-w-none",
    help: "pb-2",
    transferlist:
      "sm:w-3/5 shadow-md flex flex-col min-h-[350px] max-h-[350px] border rounded overflow-hidden select-none bg-gray-50",
    transferlistHeader:
      "flex bg-gray-100 justify-between items-center border-b p-3",
    transferlistHeaderItemCount: "ml-auto text-sm",
    transferlistListItems:
      "list-none bg-gray-50 h-full sm:max-w-xs overflow-x-hidden overflow-y-auto",
    transferlistListItem:
      "pl-8 relative aria-selected:bg-blue-600 aria-selected:data-[is-active=true]:bg-blue-600 aria-selected:text-white aria-selected:data-[is-active=true]:text-white first:-mt-px first:border-t py-2 px-3 flex relative border-b bg-white data-[is-active=true]:text-blue-500 data-[is-active=true]:bg-gray-100 cursor-pointer group-data-[is-max=true]:cursor-not-allowed items-center",
    transferlistOption: "text-sm",
    transferControls:
      "flex sm:flex-col justify-center mx-auto my-2 sm:mx-2 sm:my-auto border rounded",
    transferlistButton:
      "text-sm disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-50 first:rounded-l last:rounded-r sm:first:rounded-t sm:last:rounded-b appearance-none p-2 m-0 cursor-pointer h-10 border-none rounded-none bg-gray-50 hover:outline disabled:hover:outline-none hover:outline-1 hover:outline-black hover:text-blue-500 disabled:hover:text-current hover:z-10",
    sourceEmptyMessage:
      "appearance-none border-none w-full p-0 m-0 text-center text-gray-500 italic",
    sourceListItems: "group-data-[is-max=true]:opacity-50",
    targetEmptyMessage:
      "appearance-none border-none w-full p-0 m-0 text-center text-gray-500 italic",
    emptyMessageInner: "flex items-center justify-center p-2 text-sm",
    transferlistControls: "bg-white px-5 py-4 border-b",
    transferlistSearch: "flex border rounded items-center",
    transferlistSearchInput:
      "border-none p-1 w-full bg-transparent outline-none text-sm",
    controlLabel: "hidden",
    selectedIcon: "w-3 absolute left-3 select-none",
    fastForwardIcon:
      "w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0",
    moveRightIcon:
      "w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0",
    moveLeftIcon:
      "w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0",
    rewindIcon:
      "w-10 flex select-none [&>svg]:m-auto [&>svg]:w-full [&>svg]:max-w-[1rem] [&>svg]:max-h-[1rem] rotate-90 sm:rotate-0",
  },
  // Custom for Werkgeluk
  "multi-step": {
    outer: "data-[invalid=true]:text-dark",
    wrapper: "!max-w-none !rounded-none [counter-reset:steps]",
    steps: "!border-0 !p-0 !shadow-none",
    tabs: "bg-white p-4 lg:px-10 lg:pt-8 lg:pb-16 rounded flex-col gap-4 lg:gap-0 lg:flex-row !items-start lg:!items-center",
    tab: "!items-start transition-all [counter-increment:steps] items-center flex lg:block !flex-row before:mr-2 before:!w-[1.75rem] before:lg:!w-[1.25rem] lg:before:mr-0 before:!mb-0 before:transition-all before:!border-2 before:border-[#D8D8D8] aria-selected:before:!bg-dark aria-selected:before:!border-dark data-visited:before:!border-dark after:!h-12 after:lg:!h-[1px] after:!w-[1px] after:lg:!w-full after:!bg-[#D8D8D8] after:!top-[calc(1.25rem/2)] after:!left-[0.625rem] after:lg:!left-0 ",
    tabLabel:
      "font-bold text-left !top-[calc(100%+.5rem)] !relative lg:!absolute before:content-['Stap_'_counter(steps)] before:block before:text-xs before:!font-normal before:-mb-1",
    badge: "text-xs",
  },
};

// TODO: Also implement serverside before adding this plugin
import { VueReCaptcha } from "vue-recaptcha-v3";

/// TODO: Fill in clientside Google reCAPTCHA site key
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: "6LcANRAqAAAAAGaZ83bddXJocTHd_8ABsfxaxTZq",
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true,
    },
  });
});
